// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akademie-js": () => import("./../../../src/pages/akademie.js" /* webpackChunkName: "component---src-pages-akademie-js" */),
  "component---src-pages-aktionen-js": () => import("./../../../src/pages/aktionen.js" /* webpackChunkName: "component---src-pages-aktionen-js" */),
  "component---src-pages-aktionen-test-js": () => import("./../../../src/pages/aktionen-test.js" /* webpackChunkName: "component---src-pages-aktionen-test-js" */),
  "component---src-pages-aktionenarchiv-js": () => import("./../../../src/pages/aktionenarchiv.js" /* webpackChunkName: "component---src-pages-aktionenarchiv-js" */),
  "component---src-pages-aktionsteilnahmen-js": () => import("./../../../src/pages/aktionsteilnahmen.js" /* webpackChunkName: "component---src-pages-aktionsteilnahmen-js" */),
  "component---src-pages-anmelden-js": () => import("./../../../src/pages/anmelden.js" /* webpackChunkName: "component---src-pages-anmelden-js" */),
  "component---src-pages-anspruch-js": () => import("./../../../src/pages/anspruch.js" /* webpackChunkName: "component---src-pages-anspruch-js" */),
  "component---src-pages-apothekentour-js": () => import("./../../../src/pages/apothekentour.js" /* webpackChunkName: "component---src-pages-apothekentour-js" */),
  "component---src-pages-debug-js": () => import("./../../../src/pages/debug.js" /* webpackChunkName: "component---src-pages-debug-js" */),
  "component---src-pages-deine-apotheken-js": () => import("./../../../src/pages/deine-apotheken.js" /* webpackChunkName: "component---src-pages-deine-apotheken-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konto-aktivieren-js": () => import("./../../../src/pages/konto-aktivieren.js" /* webpackChunkName: "component---src-pages-konto-aktivieren-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nl-deaktivieren-js": () => import("./../../../src/pages/nl-deaktivieren.js" /* webpackChunkName: "component---src-pages-nl-deaktivieren-js" */),
  "component---src-pages-nutzungsbedingungen-old-js": () => import("./../../../src/pages/nutzungsbedingungen-old.js" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-old-js" */),
  "component---src-pages-passwort-vergessen-js": () => import("./../../../src/pages/passwort-vergessen.js" /* webpackChunkName: "component---src-pages-passwort-vergessen-js" */),
  "component---src-pages-passwort-zuruecksetzen-js": () => import("./../../../src/pages/passwort-zuruecksetzen.js" /* webpackChunkName: "component---src-pages-passwort-zuruecksetzen-js" */),
  "component---src-pages-praemien-js": () => import("./../../../src/pages/praemien.js" /* webpackChunkName: "component---src-pages-praemien-js" */),
  "component---src-pages-profil-js": () => import("./../../../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-punktearchiv-js": () => import("./../../../src/pages/punktearchiv.js" /* webpackChunkName: "component---src-pages-punktearchiv-js" */),
  "component---src-pages-punktehistorie-js": () => import("./../../../src/pages/punktehistorie.js" /* webpackChunkName: "component---src-pages-punktehistorie-js" */),
  "component---src-pages-punktevergabe-js": () => import("./../../../src/pages/punktevergabe.js" /* webpackChunkName: "component---src-pages-punktevergabe-js" */),
  "component---src-pages-registrieren-js": () => import("./../../../src/pages/registrieren.js" /* webpackChunkName: "component---src-pages-registrieren-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-trainings-js": () => import("./../../../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-pages-user-abgelehnt-js": () => import("./../../../src/pages/user-abgelehnt.js" /* webpackChunkName: "component---src-pages-user-abgelehnt-js" */),
  "component---src-pages-user-bestaetigt-js": () => import("./../../../src/pages/user-bestaetigt.js" /* webpackChunkName: "component---src-pages-user-bestaetigt-js" */),
  "component---src-ui-templates-news-page-js": () => import("./../../../src/ui/templates/NewsPage.js" /* webpackChunkName: "component---src-ui-templates-news-page-js" */),
  "component---src-ui-templates-reward-page-js": () => import("./../../../src/ui/templates/RewardPage.js" /* webpackChunkName: "component---src-ui-templates-reward-page-js" */),
  "component---src-ui-templates-static-page-js": () => import("./../../../src/ui/templates/StaticPage.js" /* webpackChunkName: "component---src-ui-templates-static-page-js" */),
  "component---src-ui-templates-training-page-js": () => import("./../../../src/ui/templates/TrainingPage.js" /* webpackChunkName: "component---src-ui-templates-training-page-js" */)
}

